import React from 'react';
import styled, { withTheme } from 'styled-components';
import { IS_WHITE_LABELED } from '../../appConfig';

const Wrapper = styled.div`
  overflow: hidden;
  g {
    opacity: 0.3;
  }
  path {
    stroke: ${(props) => (props.isDarkMode ? props.theme.secondary.white : props.theme.primary.charcoal)};
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
`;

const Desktop = ({ isDarkMode }) => {
  if (IS_WHITE_LABELED) {
    return null;
  }
  return (
    <Container>
      <Wrapper isDarkMode={isDarkMode}>
        <svg width="1444" height="1306" viewBox="0 0 1444 1306" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3">
            <path
              d="M593.417 1C599.534 35.9497 621.125 68.6953 647.393 109.942C717.802 219.671 807.522 501.315 573.626 679.212C367.557 836.013 152.972 1026.35 71.5283 1305"
              stroke="#ECEAE7"
              strokeWidth="1.0001"
              strokeMiterlimit="10"
            />
            <path
              d="M535.963 1C542.08 35.9497 563.67 68.6953 589.939 109.942C660.348 219.671 853.822 501.315 619.806 679.212C413.737 836.013 82.3239 1026.35 1 1305"
              stroke="#ECEAE7"
              strokeWidth="1.0001"
              strokeMiterlimit="10"
            />
            <path
              d="M663.226 1305C657.109 1270.05 635.519 1237.3 609.25 1196.06C538.842 1086.33 449.121 804.685 683.018 626.788C889.086 469.987 1103.67 279.653 1185.11 1"
              stroke="#ECEAE7"
              strokeWidth="1.0001"
              strokeMiterlimit="10"
            />
            <path
              d="M713.004 1305C635.398 1273.83 352.684 901.19 742.031 687.871C1019.35 535.793 1267.76 307.675 1443 1"
              stroke="#ECEAE7"
              strokeWidth="1.0001"
              strokeMiterlimit="10"
            />
            <path
              d="M617.646 1305C452 1127.73 471.911 804.685 705.807 626.788C911.876 470.144 1196.87 317.279 1346.8 1"
              stroke="#ECEAE7"
              strokeWidth="1.0001"
              strokeMiterlimit="10"
            />
          </g>
        </svg>
      </Wrapper>
    </Container>
  );
};

const Mobile = () => {
  if (IS_WHITE_LABELED) {
    return null;
  }
  return (
    <Container>
      <Wrapper isDarkMode>
        <svg width="919" height="831" viewBox="0 0 919 831" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3">
            <path
              d="M377.621 1C381.51 23.2188 395.236 44.0363 411.935 70.2585C456.697 140.017 513.735 319.069 365.039 432.164C234.034 531.848 97.6142 652.85 45.8374 830"
              stroke="#ECEAE7"
              strokeWidth="1.0001"
              strokeMiterlimit="10"
            />
            <path
              d="M341.095 1C344.984 23.2188 358.71 44.0363 375.41 70.2585C420.171 140.017 543.17 319.069 394.397 432.164C263.392 531.848 52.7006 652.85 1 830"
              stroke="#ECEAE7"
              strokeWidth="1.0001"
              strokeMiterlimit="10"
            />
            <path
              d="M422.001 830C418.112 807.781 404.387 786.964 387.687 760.742C342.925 690.983 285.887 511.931 434.583 398.836C565.589 299.152 702.008 178.15 753.785 1"
              stroke="#ECEAE7"
              strokeWidth="1.0001"
              strokeMiterlimit="10"
            />
            <path
              d="M453.647 830C404.31 810.183 224.578 573.283 472.1 437.669C648.401 340.987 806.324 195.965 917.732 1"
              stroke="#ECEAE7"
              strokeWidth="1.0001"
              strokeMiterlimit="10"
            />
            <path
              d="M393.024 830C287.717 717.305 300.375 511.931 449.072 398.836C580.077 299.252 761.258 202.07 856.576 1"
              stroke="#ECEAE7"
              strokeWidth="1.0001"
              strokeMiterlimit="10"
            />
          </g>
        </svg>
      </Wrapper>
    </Container>
  );
};

const TornadoLines = ({ isMobile, isDarkMode }) => {
  return isMobile ? <Mobile isDarkMode={isDarkMode} /> : <Desktop isDarkMode={isDarkMode} />;
};

export default withTheme(TornadoLines);
